import * as React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import { useLocation } from "@reach/router"
import { SwitchTransition, Transition } from 'react-transition-group';
import "../styles/main.scss"

const TRANSITION_DURATION = 400;

const TRANSITION_STYLES = {
  default: {
    transition: `opacity ${TRANSITION_DURATION}ms ease`,
  },
  entering: {
    opacity: 0,
  },
  entered: {
    opacity: 1,
  },
  exiting: {
    opacity: 0,
  },
  exited: {
    opacity: 0,
  },
};

const Layout = ({ children }) => {
  const location = useLocation();

  return (
    <React.Fragment>
      <Header />
      <SwitchTransition>
				<Transition
					key={location.pathname}
					mountOnEnter
					unmountOnExit
					appear
					timeout={TRANSITION_DURATION}
				>
					{status => (
						<main
							id="maincontent"
							style={{
								...TRANSITION_STYLES.default,
								...TRANSITION_STYLES[status],
							}}
						>
							{children}
						</main>
					)}
				</Transition>
			</SwitchTransition>

    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
