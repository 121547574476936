import React, { useState } from "react"
import { useMediaQuery } from "react-responsive"
import { Link } from "gatsby"
import ArrowDown from "../images/arrow-down.svg"
import ArrowUp from "../images/arrow-up.svg"

const Header = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  })
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" })
  const [isMenuOpen, setMenuStatus] = useState(false)

  const handleMenuOpen = () => {
    setMenuStatus(!isMenuOpen)
  }

  return (
    <header className="site-header">
      <Link to={"/"}>
        <h1 className="evvie">Evelyn Crowley</h1>
      </Link>
      {isDesktopOrLaptop && (
        <nav className="site-nav">
          <Link to="/work">Selected Work</Link>
          <a
            href="https://www.linkedin.com/in/evelyn-crowley-55748024/"
            target="_blank"
            rel="noreferrer noopener"
          >
            LinkedIn
          </a>
          <a
            href="mailto:crowley.evvie@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            Say Hi
          </a>
        </nav>
      )}
      {isTabletOrMobile && (
        <>
          <div className="mobile-trigger" onClick={handleMenuOpen}>
            {isMenuOpen ? (
              <img
                src={ArrowUp}
                alt="Open Menu Button"
                className="mobile-menu-trigger"
              />
            ) : (
              <img
                src={ArrowDown}
                alt="Close Menu Button"
                className="mobile-menu-trigger"
              />
            )}
          </div>
          {isMenuOpen ? (
            <nav className="mobile-nav">
              <Link to={"/work"} onClick={handleMenuOpen}>
                Selected Work
              </Link>
              <a
                href="https://www.linkedin.com/in/evelyn-crowley-55748024/"
                target="_blank"
                rel="noreferrer noopener"
              >
                LinkedIn
              </a>
              <a
                href="mailto:crowley.evvie@gmail.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                Say Hi
              </a>
            </nav>
          ) : (
            ""
          )}
        </>
      )}
    </header>
  )
}

export default Header
